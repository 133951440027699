export class PromoTimerHelpers {
  static formatTime = (seconds: number) => {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor(((seconds % 86400) % 3600) / 60);
    const remainingSeconds = Math.floor(((seconds % 86400) % 3600) % 60);

    const daysString = String(days).padStart(2, '0');
    const hoursString = String(hours).padStart(2, '0');
    const minutesString = String(minutes).padStart(2, '0');
    const secondsString = String(remainingSeconds).padStart(2, '0');

    return {
      days: daysString,
      hours: hoursString,
      minutes: minutesString,
      seconds: secondsString,
    };
  };
}
