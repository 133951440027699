import { PromoCodeStatus } from '@/controllers/graphql/generated';

export interface RegistrationQuery {
  courseSlug?: string;
  redirectTo?: string;
  studentSource?: string;
  selectedSlug?: string;
}

export const ExpiredStatuses = [
  PromoCodeStatus.Expired,
  PromoCodeStatus.Inactive,
];
